import React from 'react';

const customStyles = {
  layoutWrapper: {
    position: 'relative',
    zIndex: 0
  },
  logo: {
    width: '3.5rem',
    height: '3.5rem',
    top: '2rem',
    right: '2rem'
  }
}

const AppLayout = ({ children }) => {
  const { layoutWrapper, logo } = customStyles;

  return (
    <div style={layoutWrapper}>
      {children}
      <a className="logoLink" href="http://www.neoxia.com" target="_blank" rel="noopener noreferrer">
        <div className="logo" style={logo} >
        </div>
      </a>
    </div>
  );
}

export default AppLayout;