import axios from 'axios';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import Slider from 'react-slick';

import NxExpearienceComponent from './components/nxExpearienceComponent/NxExpearienceComponent';

import { PhotosArticles, Videos } from './data/data'

import './App.css';

class MyvideoPlayer extends Component {

  constructor() {
    super();
    this.vidRef = React.createRef();
  }

  toggleVideo = () => {
    let isPaused = this.vidRef.paused;
    if (isPaused) {
      this.vidRef.play()
    } else {
      this.vidRef.pause()
    }
  }

  playPause = () => {
    this.props.playPause();
  };


  render() {
    return (
      <video
        controls
        key={this.props.url}
        ref={this.vidRef}
        poster={require(`${this.props.videoPoster}`)}
        onPlay={this.playPause.bind(this, 'play')}
        onPause={this.playPause.bind(this, 'pause')}>
        <source src={this.props.url} type="video/mp4" />
				Your browser does not support the video tag. I suggest you upgrade your browser.
        <source src={this.props.urlWebm} type="video/webm" />
				Your browser does not support the video tag. I suggest you upgrade your browser.
        {/* <source src={VideoOGG} type="video/ogg" /> */}
      </video>
    )
  };
}

export default class App extends Component {

  state = {
    video: Videos,
    photosArticles: PhotosArticles,
    ActiveCityID: 1,
    seRendre: "Se rendre à la Montagne",
    // initial View is paris her ID is 1
    gotoID: 2,
    // 0.03 afin d'assurer qu'on va prendre le numbre le plus proche apres points par exp 1.412353 ===> 1.42
    // par ce que il reste toujours une marge ou padding par defaut (produise au moment de scale)
    scaleX: Number((window.innerWidth / 1024).toFixed(2)),
    scaleY: Number((window.innerHeight / 540).toFixed(2)) + 0.03,
    contactMenu: [
      {
        id: 1,
        item: 'Bureau',
      },
      {
        id: 2,
        item: 'Paris',
      },
      {
        id: 3,
        item: 'Casablanca',
      },
      {
        id: 4,
        item: 'Grenoble',
      },
      {
        id: 5,
        item: 'Bordeaux',
      }
    ],
    // if U want to set a new galerie pictures put just name of the pic in data.js
    sliders: [],
    videoUrl: [],
    CurrentModelCity: '',
    nextCity: 'Grenoble',
    IsMenuClicked: false,
    ShowModel: false,
    isPlay: false,
    ModalName: 'evenements',
    isPortrait: false,
    isStart: false,
    isJoinus: false,
    isSubmit: false,
    cityButtonDisabled: false,
    selectedContactMenu: "Bureau",
    fields: {},
    errors: {},
    selectedFile: null,
    ModelData: {},
    currentScrollPosition: 0
  };


  componentWillUnmount() {
    document.getElementsByClassName('MyParllax')[0].removeEventListener('scroll', this._handleHorizontalScroll);
  }

  componentDidMount() {
    document.getElementsByClassName('MyParllax')[0].addEventListener('scroll', this._handleHorizontalScroll);
    document.getElementsByClassName('MyParllax')[0].addEventListener('wheel', this._handleVerticalScroll);


    if (window.innerWidth > 786) {
      this.setState({
        isPortrait: false,
      });
    } else {
      this.setState({
        isPortrait: true,
      });
    }
    window.addEventListener('resize', () => {
      this.setState({
        scaleX: Number((window.innerWidth / 1024).toFixed(2)),
        scaleY: Number((window.innerHeight / 540).toFixed(2)) + 0.03,
      });

      if (window.matchMedia("(orientation: portrait)").matches) {
        this.setState({
          isPortrait: true
        });
      } else {
        this.setState({
          isPortrait: false
        });
      }

    })

    window.addEventListener("orientationchange", () => {
      this.setState({
        scaleX: Number((window.innerWidth / 1024).toFixed(2)),
        scaleY: Number((window.innerHeight / 540).toFixed(2)) + 0.03,
      })
    });

    if (window.matchMedia("(orientation: portrait)").matches) {
      this.setState({
        isPortrait: true
      })
    } else {
      this.setState({
        isPortrait: false,

      })
    }

    // displaying and reloading page when detectiong mode paysage
    window.addEventListener("orientationchange", () => {
      if (!window.matchMedia("(orientation: portrait)").matches) {
        this.setState({
          isPortrait: true,
        })
      } else {
        this.setState({
          isPortrait: false,

        })
      }
      this.setState({
        scaleX: Number((window.innerWidth / 1024).toFixed(2)),
        scaleY: Number((window.innerHeight / 540).toFixed(2)) + 0.03,
      })
    });

  }

  _playPause = () => {
    const IsPlay = this.state.isPlay;
    this.setState({
      isPlay: !IsPlay
    });
  };

  _handleHorizontalScroll = () => {
    const { space, current } = this.NxExpCompRef.myParallax.current;
    const currentCity = Math.floor(current / space);

    const activeCity = currentCity + 1;
    this.changeActiveCity(activeCity);
    switch (activeCity) {
      case 1:
        this.setState({
          seRendre: 'Se rendre à la Montagne',
          gotoID: 2,
          nextCity: 'Grenoble',
        });
        break;
      case 2:
        this.setState({
          seRendre: 'Se rendre à la Plage',
          gotoID: 3,
          nextCity: 'Paris',
        });
        break;
      case 3:
        this.setState({
          seRendre: 'Se rendre à la Capitale',
          gotoID: 1,
          nextCity: 'Paris',
        });
        break;
      default:
        break;
    }

    this.setState({
      currentScrollPosition: current
    });
  }

  _ToggleVideo() {
    this._child.toggleVideo();
  }

  // Hopefully temporary - converts the vertical scroll to an horizontal scroll
  _handleVerticalScroll = (e) => {
    if (e.deltaX === 0 && !this.state.cityButtonDisabled) {
      if (this.detectScrollUp(e)) {
        const scrollUp = this.state.currentScrollPosition + 22;

        this.setState({
          currentScrollPosition: scrollUp > 2714 ? 2714 : scrollUp,
        });
      } else {
        const scrollDown = this.state.currentScrollPosition - 22;

        this.setState({
          currentScrollPosition: scrollDown < 0 ? 0 : scrollDown,
        });
      }

      document.getElementsByClassName('MyParllax')[0].scrollTo(this.state.currentScrollPosition, 0);
      e.preventDefault();
    } else {
      const { current } = this.NxExpCompRef.myParallax.current;

      this.setState({
        currentScrollPosition: current
      });
    }
  }

  detectScrollUp = (event) => {
    if (event.wheelDelta) {
      return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
  }

  IterifyArr = (arr) => {
    let index = this.state.video.findIndex(vid => vid.url === this.state.videoUrl[0]);
    arr.currIndex = index;
    var cur = index;
    arr.next = (function () { return (++cur >= this.length) ? false : this[cur]; });
    arr.prev = (function () { return (--cur < 0) ? false : this[cur]; });
    return arr;
  }


  NavigateVideo = () => {
    this.IterifyArr(this.state.video);
    let videos = this.state.video;
    let nextVideo = videos.next();
    if (nextVideo.titre) {
      this.handleCloseModal();
      this.handleOpenModal(1, nextVideo.titre)
      this.goToCity(nextVideo.pageID);
    } else if (!nextVideo.titre) {
      this.handleOpenModal(1, "video-eric")
      this.goToCity(1);
    }
  }

  ModalsNavigation = () => {

    if (this.state.ModalName === "temoignages") {
      this.setState({
        cityButtonDisabled: true,
        isPlay: false
      });
      this.NavigateVideo();
    }
    else if (this.state.ModalName === "evenements") {
      if (this.state.CurrentModelCity === "Paris") {
        this.handleOpenModal(2, 'images-grenoble')
        this.goToCity(2);
      } else if (this.state.CurrentModelCity === "Grenoble") {
        this.handleOpenModal(2, 'images-paris');
        this.goToCity(1);
      }
      // No Bordeaux pictures yet ??
      // else {
      //   this.handleOpenModal(2, 'images-bordeaux');
      //   this.goToCity(3);
      // }
    }
    else if (this.state.ModalName === "esprit-tech") {
      if (this.state.CurrentModelCity === "Parisiens") {
        this.handleOpenModal(3, 'articles-grenoble');
        this.goToCity(2);
      } else if (this.state.CurrentModelCity === "Grenoblois") {
        this.handleOpenModal(3, 'articles-bordeaux');
        this.goToCity(3);
      } else if (this.state.CurrentModelCity === "Bordelais") {
        this.handleOpenModal(3, 'articles-paris');
        this.goToCity(1);
      }
    }
  }


  goToCity = (cityID) => {
    // I make Cityid-1 because the first city Id is 1 and default scroll view should be (0,0) 
    document.getElementsByClassName('MyParllax')[0].removeEventListener('scroll', this._handleHorizontalScroll);
    switch (cityID) {
      case 1:
        this.setState({
          ActiveCityID: cityID,
          seRendre: "Se rendre à la Montagne",
          gotoID: 2,
          cityButtonDisabled: true,
          nextCity: 'Grenoble',
        });
        break;
      case 2:
        this.setState({
          ActiveCityID: cityID,
          seRendre: "Se rendre à la Plage",
          gotoID: 3,
          cityButtonDisabled: true,
          nextCity: 'Paris',
        });
        break;
      case 3:
        this.setState({
          ActiveCityID: cityID,
          seRendre: "Se rendre à la Capitale",
          gotoID: 1,
          cityButtonDisabled: true,
          nextCity: 'Paris',
        });
        break;
      default:
        break;
    }
    this.NxExpCompRef.ScrollToCity(cityID - 1)

    setTimeout(() => {
      document.getElementsByClassName('MyParllax')[0].addEventListener('scroll', this._handleHorizontalScroll)
      this.setState({
        cityButtonDisabled: false
      })
    }, 2000);
  }



  handleOpenModal = (menuID, ModelDetails) => {
    switch (menuID) {
      case 1:
        this.setState({
          ModalName: 'temoignages',
          ShowModel: true,
        });
        break;
      case 2:
        this.setState({
          ModalName: 'evenements',
          ShowModel: true
        });
        break;
      case 3:
        this.setState({
          ModalName: 'esprit-tech',
          ShowModel: true
        });
        break;
      default:
        this.setState({
          ModalName: 'esprit-tech',
          ShowModel: true
        });
        break;
    }
    this.GetModelData(ModelDetails);
  };


  GetModelData = DataInfos => {
    var prefix = DataInfos.split('-');
    switch (prefix[0]) {
      case 'video':
        const SelectedVideo = this.state.video.find(v => v.titre === DataInfos);
        this.setState({
          videoUrl: [SelectedVideo.url, SelectedVideo.urlWebm, SelectedVideo.poster, SelectedVideo.titre],
          CurrentModelCity: SelectedVideo.titreModel
        });
        break;
      case 'images':
        const SelectedImages = this.state.photosArticles.find(i => i.ville.toLowerCase() === prefix[1].toLowerCase());
        this.setState({
          sliders: [...SelectedImages.images],
          CurrentModelCity: SelectedImages.ville,
        });
        break;
      case 'articles':
        const SelectedArticle = this.state.photosArticles.find(i => i.ville.toLowerCase() === prefix[1].toLowerCase());
        this.setState({
          sliders: [...SelectedArticle.articles],
          CurrentModelCity: SelectedArticle.villeAlias
        });
        break;
      default:
        const DefaultArticle = this.state.photosArticles.find(i => i.ville.toLowerCase() === prefix[1].toLowerCase());
        this.setState({
          ModalData: DefaultArticle
        });
        break;
    }
  }


  handleCloseModal = () => {
    this.setState({ ShowModel: false, isPlay: false });
  };


  changeActiveCity = (id) => {
    this.setState({
      ActiveCityID: id
    })
  }

  closeContact = () => {
    this.setState({
      isJoinus: false,
      isStart: true
    })
  }

  goTosite = () => {
    this.setState({
      isStart: true,
      isSubmit: false,
      isJoinus: false
    })

  }


  Joinus = () => {
    this.setState({
      isJoinus: true,
      isStart: false,
      isSubmit: false
    })
  }


  toggleMenu = () => {
    const MenuClickedState = this.state.IsMenuClicked;
    this.setState({
      IsMenuClicked: !MenuClickedState
    });
  };

  selectContactMenu = SelectedContactItem => {
    let fields = this.state.fields;
    fields["ville"] = SelectedContactItem.item;
    this.setState({
      selectedContactMenu: SelectedContactItem.item,
      fields: fields,
      IsMenuClicked: false
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.validateForm());
    // if (this.validateForm()) {
    // 	this.setState({
    // 		fields: {},
    // 		isSubmit: true
    // 	});
    // }
    this.setState({
      isSubmit: true,
      fields: {}
    });

  }


  handleChange = (e) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }


  validateForm = () => {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["prenom"]) {
      formIsValid = false;
      errors["prenom"] = "*Merci de saisir votre Prenom.";
    }

    if (typeof fields["prenom"] !== "undefined") {
      if (!fields["prenom"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["prenom"] = "*Veuillez saisir uniquement des caractères alphabétiques.";
      }
    }

    if (!fields["nom"]) {
      formIsValid = false;
      errors["nom"] = "*Merci de saisir votre Nom.";
    }

    if (typeof fields["nom"] !== "undefined") {
      if (!fields["nom"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["nom"] = "*Veuillez saisir uniquement des caractères alphabétiques.";
      }
    }


    if (!fields["mail"]) {
      formIsValid = false;
      errors["mail"] = "*Merci de saisir votre adresse email.";
    }

    if (typeof fields["mail"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(fields["mail"])) {
        formIsValid = false;
        errors["mail"] = "*Merci de saisir une adresse e-mail valide.";
      }
    }

    if (!fields["conatctmsg"]) {
      formIsValid = false;
      errors["conatctmsg"] = "*Merci de saisir votre Le message de Contact.";
    }

    if (typeof fields["conatctmsg"] !== "undefined") {
      if (!fields["conatctmsg"].match(/^[a-zA-Z ]*$/)) {
        formIsValid = false;
        errors["conatctmsg"] = "*Veuillez saisir uniquement des caractères alphabétiques.";
      }
    }

    this.setState({
      errors: errors
    });
    return formIsValid;


  }

  // On file select (from the pop up) 
  onFileChange = event => {
    // Update the state 
    this.setState({ selectedFile: event.target.files[0] });
  };

  sendEmail = () => {
    const bodyFormData = new FormData();
    const { conatctmsg, mail, prenom, nom } = this.state.fields;
    const { selectedFile } = this.state;
    
    bodyFormData.append("body", conatctmsg);
    bodyFormData.append("subject", "Recrutement");
    bodyFormData.append("sender_email", mail);
    bodyFormData.append("sender_last_name", nom);
    bodyFormData.append("sender_first_name", prenom);
    bodyFormData.append("attachment", selectedFile);

    const requestOptions = {
      url: "https://live-preprod.neoxia.com/api-contacts/", // switch to live.neoxia.com
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: bodyFormData
    };
    axios
      .request(requestOptions)
      .then((response) => response.json())
      .then((response) => console.log(response));
  }
  
  render() {
    const settings = {
      centerMode: true,
      variableWidth: true,
      infinite: true,
      slidesToShow: 1
    };

    return (
      // Our Application Content 
      <div className="fullContainer">

        {
          this.state.isPortrait ?
            // message to display when Portrait Mode or on small devices
            <div className="PortraitMessage">
              <div className="Infomessage"> 	Merci d'utilser le Mode Paysage </div>
            </div> :


            !this.state.isJoinus && !this.state.isStart ?
              // Welcome poppup
              <div className="startScreen">

                <div className="StartModal">
                  <h1 className="mt-30">Bienvenue chez Neoxia</h1>
                  <p className="mt-30">Pour te déplacer dans la frise, utilise les flèches de ton clavier ou le scroll ! </p>
                  <div className="icons mt-20">
                    <div className="navigation-icons">
                      <span className="square">
                        <img alt={'Start Chevron Left'} src={require('./assets/images/start-chevron-left.png')} />
                      </span>
                      <span className="square">
                        <img alt={'Start Chevron Right'} src={require('./assets/images/start-chevron-right.png')} />
                      </span>
                      <span className="mouse">
                        <img alt={'Mouse'} src={require('./assets/images/mouse.png')} />
                      </span>
                    </div>
                  </div>
                  <p className="mt-20">Clique sur les icones vertes !</p>
                  <p className="welcomeicons mt-20">
                    <img alt={'Welcome Icon 1'} src={require('./assets/images/welcome-icon1.png')} />
                    <img alt={'Welcome Icon 2'} src={require('./assets/images/welcome-icon2.png')} />
                    <img alt={'Welcome Icon 3'} src={require('./assets/images/welcome-icon3.png')} />
                  </p>
                  <p className="mt-40 start"
                    onClick={this.goTosite}>
                    <span>
                      Commencer l'expérience
												</span>
                  </p>
                  {/* <img alt={'Start Chevron'} src={require('./assets/images/start-chevron-right.png')} /> */}
                </div>
              </div> :

              this.state.isJoinus && !this.state.isSubmit && !this.state.isStart ?
                // JoinUs contact poppup
                <div className="startScreen">

                  <div className="contactform">
                    <span className="closebtn" onClick={this.closeContact}>
                      <img alt={'Close Button'} src={require('./assets/images/closebtn.png')} />
                    </span>
                    <div className="contactDesc">
                      <span>Tu cherches un stage, une alternance, un cdi ?</span>
                      <span>Laisses nous ton Cv on te recontactera !</span>
                    </div>
                    <div className="form">
                      <form onSubmit={this.handleSubmit} method="post" name="submitForm" >
                        <div className={this.state.IsMenuClicked ? 'dropdown active ' : 'dropdown '}>
                          <div className="sidemenu contactMenu">
                            <div className="menuHead" onClick={this.toggleMenu}>
                              <span className="item-name">{this.state.selectedContactMenu}</span>
                              <span className="item-image">
                                <img
                                  className={this.state.IsMenuClicked ? 'ShowB ' : 'Hide '}
                                  alt={'hello2'}
                                  src={require('./assets/images/top-arrow.png')}
                                />

                                <img
                                  className={!this.state.IsMenuClicked ? 'ShowB ' : 'Hide '}
                                  alt={'hello2'}
                                  src={require('./assets/images/bottom-arrow.png')}
                                />
                              </span>
                            </div>
                            <span className="menuItems">
                              <React.Fragment>
                                <div className={this.state.IsMenuClicked ? 'ShowB items' : 'Hide items'}>
                                  <ul>
                                    {this.state.contactMenu.map(listitem =>
                                      <li
                                        key={listitem.id}
                                        className="menu-item"
                                        onClick={this.selectContactMenu.bind(this, listitem)}>
                                        <span className="item-name ">
                                          {listitem.item}
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </React.Fragment>
                            </span>
                          </div>
                        </div>
                        <div className="fullname">
                          <span className="inputs">
                            <input placeholder="Nom *" type="text" name="prenom" autoComplete="off"
                              value={this.state.fields.prenom || ""} onChange={this.handleChange} className="prenom" />
                            <span className="errorMsg">{this.state.errors.nom}</span>
                          </span>
                          <span className="inputs">
                            <input placeholder="Prénom *" type="text" name="nom" autoComplete="off"
                              value={this.state.fields.nom || ""} onChange={this.handleChange} className="nom" />
                            <span className="errorMsg">{this.state.errors.prenom}</span>
                          </span>
                        </div>
                        <div className="mail">
                          <span className="inputs">
                            <input placeholder="mail *" type="email" name="mail" autoComplete="off"
                              value={this.state.fields.mail || ""} onChange={this.handleChange} className="mail" />
                            <span className="errorMsg">{this.state.errors.mail}</span>
                          </span>
                        </div>
                        <div className="conatctMessage">
                          <span className="inputs">
                            <textarea rows="5" cols="10" wrap="soft" placeholder="Message *" type="text" autoComplete="off" name="conatctmsg"
                              value={this.state.fields.conatctmsg || ""} onChange={this.handleChange} className="Message" />
                            <span className="errorMsg">{this.state.errors.conatctmsg}</span>
                          </span>
                        </div>
                        <div className="pj">
                          <label className="pjLabel" htmlFor="pj">cv</label>
                          <span className="pjcontent">
                            <span className="cv">
                              <label htmlFor="pj">{!this.state.selectedFile ? 'Ajouter mon CV' : this.state.selectedFile.name}</label>
                              <input type="file" id="pj" name="pj" className="pj" onChange={this.onFileChange} />
                            </span>
                          </span>
                          <span className="errorMsg">{this.state.errors.pj}</span>
                          <span className="sentbtn">
                            <button type="submit" className="sent" onClick={this.sendEmail}>
                              <span>
                                ENVOYER
															</span>
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div> :

                this.state.isJoinus && this.state.isSubmit ?
                  // success contact sent page
                  <div className="startScreen">
                    <div className="contactSent">
                      <div className="messageSent">
                        <span>Merci pour ton CV !</span>
                        <span>Nous te recontacterons bientôt </span>
                      </div>
                      <div className="imageSent">
                        <img alt={'Contact Img'} src={require('./assets/images/conatctimage.png')} />
                      </div>
                      <div className="backtoSite start">
                        <span onClick={this.goTosite}  >Retour sur le site neoxia</span>
                      </div>
                    </div>
                  </div>

                  : null
        }

        <div className="SideMenuContainer" style={{
          transform: `scale(${this.state.scaleX}, ${this.state.scaleX})`
        }}>

          <ReactModal
            contentLabel="Nx-experiance Model"
            onRequestClose={this.handleCloseModal}
            shouldCloseOnOverlayClick={true}
            isOpen={this.state.ShowModel}
            onCloseModal={this.handleCloseModal}
            ariaHideApp={false} >
            <div className="headerModel">
              <span className="closeModel" onClick={this.handleCloseModal}>
                <img alt={'Close Model'} src={require('./assets/images/closebtn.png')} />
              </span>
              <div className="titleModel">
                {
                  this.state.ModalName === 'esprit-tech'
                    ? <span> Découvrez les articles Medium des {this.state.CurrentModelCity} ! </span>
                    : this.state.ModalName === 'temoignages'
                      ? <span className="temTitle">{this.state.CurrentModelCity} ! </span>
                      : this.state.ModalName === 'evenements'
                        ? <span> Découvrez les locaux de {this.state.CurrentModelCity} !</span>
                        : null
                }
              </div>
            </div>

            <div className={this.state.ModalName === 'esprit-tech' ? 'tech contentModel' : 'contentModel'} >
              {
                this.state.ModalName === 'esprit-tech'
                  ? <span className="articles">
                    <div className="grp">
                      <div className="article">
                        <a
                          href={this.state.sliders[0].href}
                          target="_blank"
                          rel="noopener noreferrer" >
                          <img
                            alt={this.state.sliders[0].alt}
                            src={require(`${this.state.sliders[0].imageSrc}`)}
                          />
                        </a>
                      </div>
                      <div className="article">
                        <a
                          href={this.state.sliders[1].href}
                          target="_blank"
                          rel="noopener noreferrer" >
                          <img
                            alt={this.state.sliders[1].alt}
                            src={require(`${this.state.sliders[1].imageSrc}`)}
                          />
                        </a>
                      </div>
                    </div>

                    <div className="grp">
                      <div className="article">
                        <a
                          href={this.state.sliders[2].href}
                          target="_blank"
                          rel="noopener noreferrer" >
                          <img
                            alt={this.state.sliders[2].alt}
                            src={require(`${this.state.sliders[2].imageSrc}`)}
                          />
                        </a>
                      </div>
                      <div className="article">
                        <a
                          href={this.state.sliders[3].href}
                          target="_blank"
                          rel="noopener noreferrer" >
                          <img
                            alt={this.state.sliders[3].alt}
                            src={require(`${this.state.sliders[3].imageSrc}`)}
                          />
                        </a>
                      </div>
                    </div>
                  </span>
                  : this.state.ModalName === 'temoignages'
                    ? <div className="videoPlayer">
                      <div className={!this.state.isPlay ? 'ShowF visible playButton' : 'hidden playButton'}
                        onClick={this._toggleVideo}>
                        <img
                          src={require('./assets/images/playVideo.png')}
                          alt="VideoPlay Button"
                        />
                      </div>
                      <MyvideoPlayer
                        url={this.state.videoUrl[0]}
                        urlWebm={this.state.videoUrl[1]}
                        videoPoster={this.state.videoUrl[2]}
                        isPlay={this.state.isPlay}
                        playPause={this._playPause}
                        ref={component => this._child = component} />
                    </div>
                    : this.state.ModalName === 'evenements'
                      ? <div className="galerie">
                        <Slider {...settings}>
                          {this.state.sliders.map((data, i) => {
                            return (
                              <div className="item" key={i}>
                                {/* <img alt={data} src={`${data}`} /> */}
                                <img alt={data} src={require(`${data}`)} />
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                      : null
              }
            </div>

            <div className="moreSectionModel">
              {this.state.ModalName === 'esprit-tech'
                ?
                <a href='https://medium.com/neoxia' target='_blank' rel="noopener noreferrer">
                  <span>Voir tous les Articles</span>
                </a>
                : this.state.ModalName === 'temoignages'
                  ?
                  <button onClick={this.ModalsNavigation} disabled={this.state.cityButtonDisabled}>
                    <span >Voir d'autres temoignages </span>
                  </button>
                  : this.state.ModalName === 'evenements'
                    ?
                    <button onClick={this.ModalsNavigation} disabled={this.state.cityButtonDisabled}>
                      <span>Voir Les locaux de {this.state.nextCity}</span>
                    </button>
                    : null}
            </div>

            <div className="footerM">

              <div className="footerModel">
                <p>On reste en contact ?</p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UCAUBP5-1xO7-BrYT3N8xjOg"
                  >
                    <img alt={'youtube'} src={require('./assets/images/youtube.png')} />
                  </a>
                </p>
                <p>
                  <a target="_blank" rel="noopener noreferrer" href="https://medium.com/neoxia">
                    <img alt={'medium'} src={require('./assets/images/medium.png')} />
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/neoxia/"
                  >
                    <img alt={'linkedin'} src={require('./assets/images/linkedin.png')} />
                  </a>
                </p>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/neoxia_fr/?hl=fr"
                  >
                    <img alt={'instagram'} src={require('./assets/images/instagram.png')} />
                  </a>
                </p>
              </div>

              <div className="neoxia">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://neoxia.com/"
                >
                  <span>NEOXIA.COM</span>
                </a>
              </div>

            </div>
          </ReactModal>

        </div>

        <div className={this.state.isPortrait ? 'hidden' : 'wrapper'}
          style={{
            transform: `scale(${this.state.scaleX}, ${this.state.scaleY})`
          }}>

          <div className="screenview" onScroll={this.handleScroll}>
            <div className="citytitle">
              <NxExpearienceComponent
                showMenu={this.toggleMenu}
                onJoinuscLick={this.Joinus}
                handelCityChange={this.changeActiveCity}
                scaleX={this.state.scaleX}
                scaleY={this.state.scaleY}
                IsMenuClicked={this.state.IsMenuClicked}
                handleOpenModal={this.handleOpenModal}
                {...this.props}
                onRef={ref => this.NxExpCompRef = ref} />
            </div>
          </div>

          <div className="footer">
            <div className="footer-item">
              <button className={this.state.ActiveCityID === 1 ? "active" : ""}
                disabled={this.state.cityButtonDisabled} onClick={() => this.goToCity(1)} >
                PARIS
							</button>
            </div>

            <div className="footer-item">
              <button className={this.state.ActiveCityID === 2 ? "active" : ""}
                disabled={this.state.cityButtonDisabled} onClick={() => this.goToCity(2)} >
                GRENOBLE
							</button>
            </div>

            <div className="footer-item">
              <button className={this.state.ActiveCityID === 3 ? "active" : ""}
                disabled={this.state.cityButtonDisabled} onClick={() => this.goToCity(3)} >
                BORDEAUX-PAU
							</button>
            </div>

            <div className="footer-item">
              <button disabled={this.state.cityButtonDisabled} onClick={() => this.goToCity(this.state.gotoID)} >
                {this.state.seRendre}
              </button>
            </div>
          </div>

          <div className="copyright">
            <p>All right Reserved Neoxia 2020 - Espace recrutement - Neoxia.com</p>
          </div>
        </div>
      </div >
    );
  }
}
