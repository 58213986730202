export const PhotosArticles = [
    {
        ville: 'Paris',
        villeAlias: 'Parisiens',
        images: [
            './assets/images/paris-1.jpg',
            './assets/images/paris-2.jpg',
            './assets/images/paris-3.jpg',
            './assets/images/paris-4.jpg',
            './assets/images/paris-5.jpg',
            './assets/images/paris-6.jpg',
            './assets/images/paris-7.jpg'
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-1.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-2.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-3.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-4.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-5.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-6.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/paris-7.jpg?authuser=0'
        ],
        articles: [
            {
                href: 'https://medium.com/neoxia/airflow-quels-pi%C3%A8ges-%C3%A9viter-lors-dun-d%C3%A9ploiement-%C3%A0-grande-%C3%A9chelle-f27fab7b0c2a',
                alt: 'Airflow : quels pièges éviter lors d\'un déploiement grande échelle',
                imageSrc: './assets/images/articles/air-flow.png'
            },
            {
                href: 'https://medium.com/neoxia/microservices-3d81b99ee440',
                alt: 'Microservices : CI/CD, environnement, monitoring et logs',
                imageSrc: './assets/images/articles/microservices.png'
            },
            {
                href: 'https://medium.com/neoxia/quelle-posture-adopter-face-au-trio-vue-js-react-angular-ab13a3785329',
                alt: 'Quelle posture adopter face au trio Vue.js / React / Angular ?',
                imageSrc: './assets/images/articles/framworks.png'
            },
            {
                href: 'https://medium.com/neoxia/le-design-system-acc%C3%A9l%C3%A9rateur-de-la-conception-des-projets-2833a931c86f',
                alt: 'Le design system, accélérateur de la conception des projets',
                imageSrc: './assets/images/articles/designsystem.png'
            },
        ]
    },
    {
        ville: 'Grenoble',
        villeAlias: 'Grenoblois',
        images: [
            './assets/images/grenoble-1.jpg',
            './assets/images/grenoble-2.jpg',
            './assets/images/grenoble-3.jpg',
            './assets/images/grenoble-4.jpg',
            './assets/images/grenoble-5.jpg',
            // 'https://storage.googleapis.com/nx-recrutement-assets/grenoble-1.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/grenoble-2.jpg?authuser=0', 
            // 'https://storage.googleapis.com/nx-recrutement-assets/grenoble-3.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/grenoble-4.jpg?authuser=0',
            // 'https://storage.googleapis.com/nx-recrutement-assets/grenoble-5.jpg?authuser=0'
        ],
        articles: [
            {
                href: 'https://medium.com/neoxia/api-industrialisation-fb35e41f2ac2',
                alt: 'API & industrialisation : intégration et déploiement continu',
                imageSrc: './assets/images/articles/api-deploiement.png'
            },
            {
                href: 'https://medium.com/neoxia/microservices-3d81b99ee440',
                alt: 'De Paris à Grenoble',
                imageSrc: './assets/images/articles/paris-grenoble.png'
            },
            {
                href: 'https://medium.com/neoxia/quelle-posture-adopter-face-au-trio-vue-js-react-angular-ab13a3785329',
                alt: 'Démarche de déploiement d’une stratégie API : reconnaître le besoin',
                imageSrc: './assets/images/articles/api-deploiement.png'
            },
            {
                href: 'https://medium.com/neoxia/agilit%C3%A9-les-vertus-inesp%C3%A9r%C3%A9es-dune-fiche-de-synth%C3%A8se-bfbd90aa3848',
                alt: 'Agilité : les vertus inespérées d’une fiche de synthèse',
                imageSrc: './assets/images/articles/agilite.png'
            },
        ]
    },
    {
        ville: 'Bordeaux',
        villeAlias: 'Bordelais',
        images: [],
        articles: [
            {
                href: 'https://medium.com/skale-5/skale-5-academy-1%C3%A8re-promotion-1e1728750ef0',
                alt: 'Skale-5 Academy : première promotion',
                imageSrc: './assets/images/articles/Skale5-academy.png'
            },
            {
                href: 'https://medium.com/skale-5/aws-eks-cluster-node-group-et-terraform-84a0116aa1b5',
                alt: 'AWS EKS Cluster, Node Group et Terraform',
                imageSrc: './assets/images/articles/AWS-EKS.png'
            },
            {
                href: 'https://medium.com/skale-5/oui-nous-recrutons-a0728871476',
                alt: 'Oui nous recrutons',
                imageSrc: './assets/images/articles/nous-recrutons.png'
            },
            {
                href: 'https://medium.com/skale-5/noops-arrive-48b67e6b89c2',
                alt: 'NoOps arrive ??',
                imageSrc: './assets/images/articles/NoOps.png'
            },
        ]
    },
]


export const Videos = [
    {
        titre: "video-eric",
        titreModel: " Découvrez le témoignage  D'Eric",
        pageID: 1,
        poster: './assets/images/Eric.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Eric.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Eric.webm",
    },
    {
        titre: "video-ciro-matthieu",
        titreModel: " Découvrez le témoignage De Ciro & Matthieu",
        pageID: 1,
        poster: './assets/images/Ciro-Matthieu.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Ciro-Matthieu.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Ciro-Matthieu.webm",
    },
    {
        titre: "video-alexandre",
        titreModel: " Découvrez le témoignage D'Alexandre",
        pageID: 1,
        poster: './assets/images/Alexandre.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Alexandre.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Alexandre.webm",
    },
    {
        titre: "video-kaouther-olivier",
        titreModel: " Découvrez le témoignage De Kaouther & Olivier",
        pageID: 1,
        poster: './assets/images/Olivier-Kaouther.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Olivier-Kaouther.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Olivier-Kaouther.webm",
    },
    {
        titre: "video-bureaux-paris",
        titreModel: " Découvrez le quotidien dans les bureaux de paris",
        pageID: 1,
        poster: './assets/images/Paris-bureaux.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Paris-bureaux.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Paris-Bureaux.webm",
    },
    {
        titre: "video-cognées",
        titreModel: "En route pour l'afterwork des cognées",
        pageID: 1,
        poster: './assets/images/cognées.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/cogn%C3%A9es.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Cogn%C3%A9es.webm",
    },
    {
        titre: "video-grenoble-clemence",
        titreModel: " Découvrez le témoignage De Clemence",
        pageID: 1,
        poster: './assets/images/Clemence.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Clemence.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Clemence.webm",
    },
    {
        titre: "video-ski",
        titreModel: " Découvrez le weekend Nx-ski",
        pageID: 2,
        poster: './assets/images/nx-ski-20.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/nx-ski-20.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Nx-Ski-20.webm",
    },
    {
        titre: "video-mario-ghizlane",
        titreModel: " Découvrez le témoignage De Mario & Ghizlane",
        pageID: 2,
        poster: './assets/images/Mario-Ghizlane.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Mario-Ghizlane.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Mario-Ghizlane.webm",
    },
    {
        titre: "video-theo-charles",
        titreModel: " Découvrez le témoignage De Theo & Charles",
        pageID: 2,
        poster: './assets/images/Theo-Charles.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Theo-Charles.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Theo-Charles.webm",
    },
    {
        titre: "video-vendanges",
        titreModel: "Le premier millésime de Neoxia",
        pageID: 2,
        poster: './assets/images/vendanges.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/vendanges.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Vendanges.webm",
    },
    {
        titre: "video-olivier",
        titreModel: " Découvrez le témoignage D'Olivier",
        pageID: 3,
        poster: './assets/images/Olivier.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/Olivier.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Olivier.webm",
    },
    {
        titre: "video-kart",
        titreModel: "Une aprem team building Karting",
        pageID: 3,
        poster: './assets/images/kart.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/kart.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Kart.webm",
    },
    {
        titre: "video-NX-summer",
        titreModel: " Découvrez le  weekend Nx-Summer",
        pageID: 3,
        poster: './assets/images/nx-summer.png',
        url: "https://storage.googleapis.com/nx-recrutement-assets/nx-summer.mp4",
        urlWebm: "https://storage.googleapis.com/nx-recrutement-assets/Nx-Summer.webm",
    },
    // {
    //     titre: "video-thomas-thilbault",
    //     titreModel: "De Thomas & Thilbault", 
    //     url: "https://storage.googleapis.com/nx-recrutement-assets/Thomas-Thilbault.mp4",
    // },
]
