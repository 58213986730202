import React, { Component, createRef } from 'react';

import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';

import './NxExpearienceComponent.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const PAGES = 3.65;
export default class NxExpearienceComponent extends Component {

  state = {
    menu: [
      {
        id: 1,
        item: 'Témoignages',
      },
      {
        id: 2,
        item: 'Evenements',
      },
      {
        id: 3,
        item: 'Esprit Tech',
      }
    ]
  };

  constructor(props) {
    super(props)
    this.myParallax = createRef()
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(null)
  }

  ScrollToCity = (PageToScroll) => {
    this.myParallax.current.scrollTo(PageToScroll)
  }

  Joinus = () => {
    this.props.onJoinuscLick();
  }

  showMenu = () => {
    this.props.showMenu();
  }

  // 1024, 450 screen dimentions that we scale to fill whole page

  render() {
    return (
      <span className="MyContainer">
        {/* Menus and Logo (inside Application not include forms and poppups Modols logo) */}

        <>
          <div className="sidemenu joinus" onClick={this.Joinus}>
            <div className="menuHead" >
              <span className="item-name">REJOINS-NOUS !</span>
              <span className="item-image">
                <img
                  className="ShowB"
                  alt={'hello2'}
                  src={require('../../assets/images/right-arrow-white.png')}
                />
              </span>
            </div>
          </div>

          <div className="sidemenu">
            <div className="menuHead" onClick={this.showMenu}>
              <span className="item-name">LEGENDE</span>
              <span className="item-image">
                <img
                  className={this.props.IsMenuClicked ? 'ShowB ' : 'Hide '}
                  alt={'hello2'}
                  src={require('../../assets/images/top-arrow.png')}
                />

                <img
                  className={!this.props.IsMenuClicked ? 'ShowB ' : 'Hide '}
                  alt={'hello2'}
                  src={require('../../assets/images/bottom-arrow.png')}
                />
              </span>
            </div>
            <span className="menuItems">
              <React.Fragment>
                <div className={this.props.IsMenuClicked ? 'ShowB items' : 'Hide items'}>
                  <ul>
                    {this.state.menu.map(listitem =>
                      <li
                        key={listitem.id}
                        className="menu-item"
                      // onClick={this.handleOpenModal.bind(this, listitem.id)}
                      >
                        <span className="item-image" />
                        <span className="item-name">
                          {listitem.item}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </React.Fragment>
            </span>
          </div>

        </>

        {/* Parallax == our Applications */}

        <Parallax className='MyParllax' scrolling={true} horizontal ref={this.myParallax} pages={PAGES}>
          {/* every layer offset mean page Id (by default first page PARIS have offset 0) -we divide sky per page- */}
          <ParallaxLayer offset={0} speed={.2}>
            <img className="sky" alt={'sky paris'} src={require('../../assets/images/ciel-paris2.png')} style={{ display: 'flex', height: "calc(100% - 45px)" }} />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={.2}>
            <img className="sky" alt={'sky 2'} src={require('../../assets/images/sky2.png')} style={{ display: 'flex', height: "calc(100% - 45px)" }} />
          </ParallaxLayer>

          <ParallaxLayer offset={2} speed={.2}>
            <img className="sky" alt={'sky 3'} src={require('../../assets/images/sky3.png')} style={{ display: 'flex', height: "calc(100% - 45px)" }} />
          </ParallaxLayer>

          <ParallaxLayer offset={3} speed={.2}>
            <img className="sky" alt={'sky 4'} src={require('../../assets/images/sky4.png')} style={{ display: 'flex', height: "calc(100% - 45px)" }} />
          </ParallaxLayer>


          <ParallaxLayer offset={0.85} data="1" speed={0.15} style={{ opacity: 1 }}>
            <img alt={'three'} src={require('../../assets/images/P1trans1.png')} style={{ position: 'absolute', bottom: 19, display: 'flex', width: '45%', marginLeft: '-5%', transform: "scale(0.8)" }} />
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.25} style={{ opacity: 1, bottom: "-32px" }}>
            <img alt={'Eiffel Tour'} src={require('../../assets/images/immeuble-fond-back-Tour-Eiffel.png')} style={{ display: 'block', width: '100%', marginLeft: '3%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={0} speed={0.45} style={{ opacity: 1, bottom: "-32px" }}>
            <img alt={'Front Building'} src={require('../../assets/images/immeuble-devant-front.png')} style={{ display: 'block', width: '100%', marginLeft: '0%' }} />

            <div className="binome" />

            <div className="flagleft" />

            <div className="flagright" />

            <div className="women" />
          </ParallaxLayer>


          <ParallaxLayer offset={1} speed={0.1} style={{ opacity: 1 }}>
            <div className="birdsgrenoble1" />
            <div className="birdsgrenoble" />
            <div className="birdsgrenoble gb2" />
            <div className="birdsgrenoble2" />
            <img alt={'Grenouble Montain'} src={require('../../assets/images/grenobleP1.png')} style={{ position: 'absolute', bottom: 40, display: 'block', width: '100%', marginLeft: '0%' }} />
            <div className="birdsgrenoble2 b1" />
            <div className="birdsgrenoble2 b2" />
            <div className="birdsgrenoble2 b3" />
            <div className="birdsgrenoble b4 " />

          </ParallaxLayer>


          <ParallaxLayer offset={1} speed={0.1} style={{ opacity: 1 }}>
            <img alt={'Grenoble Houses'} src={require('../../assets/images/grenobleP2.png')} style={{ position: 'absolute', bottom: 42, display: 'block', width: '100%', marginLeft: '0%' }} />
            <img alt={'Ferme'} src={require('../../assets/images/P2trans1.png')} style={{ position: 'absolute', right: '-2.5%', bottom: 40, display: 'block', width: '35%', marginLeft: '0%' }} />
            <div className="flagleft genobleflag" /> 	</ParallaxLayer>

          <ParallaxLayer offset={1} speed={0.1} style={{ opacity: 1 }}>
            <div className="homecat"></div>
            <img alt={'three'} src={require('../../assets/images/tree.png')} className="test" style={{ position: 'absolute', bottom: 40, display: 'block', width: '7%', marginLeft: '-3%' }} />
            <img alt={'Home'} src={require('../../assets/images/home.png')} className="test" style={{ position: 'absolute', bottom: 40, display: 'block', width: '10%', marginLeft: '22%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={1} speed={0.15} style={{ opacity: 1 }}>
            <div className="filsauvelo"></div>
          </ParallaxLayer>


          <ParallaxLayer offset={0.75} speed={0.4} style={{ opacity: 1 }}>
            <img alt={'Tram'} src={require('../../assets/images/P1trans2.png')} style={{ position: 'absolute', left: '31%', bottom: 25, display: 'block', width: '425px', marginLeft: '-9%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={0.75} speed={0.40} style={{ opacity: 1 }}>
            <div className="papy" />
            {/*  clicked element Amp above three */}
            <div className="amp ampthree"
              onClick={() => { this.props.handleOpenModal(1, 'video-cognées') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />

            {/*  clicked element Message left*/}
            <div className="message papymsg"
              onClick={() => { this.props.handleOpenModal(1, 'video-grenoble-clemence') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />
          </ParallaxLayer>


          <ParallaxLayer offset={1.95} speed={0.15} style={{ opacity: 1 }}>
            <img alt={'Castle ground'} src={require('../../assets/images/P3trans2.png')} style={{ position: 'absolute', left: '5%', bottom: 40, display: 'block', width: '55%', marginLeft: '0%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={2.33} speed={0.1} style={{ opacity: 1 }}>
            <img alt={'Beach'} src={require('../../assets/images/bordeauP1.png')} style={{ position: 'absolute', bottom: 40, display: 'block', width: '110%', marginLeft: '-10%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={2.33} speed={0.17} style={{ opacity: 1 }}>
            <div className="birdsgrenoble2 birdsplage P1" />
            <div className="birdsgrenoble2 birdsplage P2" />
            <div className="birdsgrenoble2 birdsplage P3" />
            <img alt={'Surfing'} src={require('../../assets/images/bordeauP2.png')} style={{ position: 'absolute', bottom: 38, display: 'block', width: '85%', marginLeft: '6%' }} />
            <img alt={'Beach Accessoire'} src={require('../../assets/images/bordeauP4.png')} style={{ position: 'absolute', bottom: 115, display: 'block', width: '20%', marginLeft: '20%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={2.35} speed={0.2} style={{ opacity: 1 }}>
            <img alt={'Tower'} src={require('../../assets/images/bordeauP3.png')} style={{ position: 'absolute', bottom: 20, display: 'block', width: '70%', marginLeft: '14%' }} />
          </ParallaxLayer>

          <ParallaxLayer offset={2.5} speed={0.25} style={{ opacity: 1 }}>
            <div className="velovoiture" />
          </ParallaxLayer>


          <ParallaxLayer offset={1.95} speed={0.15} style={{ opacity: 1 }}>
            <img alt={'Building'} src={require('../../assets/images/P3trans3.png')} style={{ position: 'absolute', left: '5%', bottom: 115, display: 'block', width: '45%', marginLeft: '0%' }} />
          </ParallaxLayer>


          <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 1 }}>
            <img alt={'three'} src={require('../../assets/images/P2trans2.png')} style={{ position: 'absolute', right: '-2.5%', bottom: 20, display: 'block', width: '35%', marginLeft: '0%' }} />
          </ParallaxLayer>


          <ParallaxLayer offset={1.98} speed={0.2} style={{ opacity: 1 }}>
            <img alt={'Castle'} src={require('../../assets/images/P3trans1.png')} style={{ position: 'absolute', left: '8%', bottom: 60, display: 'block', width: '45%', marginLeft: '0%' }} />
            <div className="birdsgrenoble2 birdsbordeau" />
          </ParallaxLayer>

          <ParallaxLayer offset={1.98} speed={0.23} style={{ opacity: 1 }}>
            <div className="filleauchien"></div>
            <div className="filsauskate"></div>
          </ParallaxLayer>


          <ParallaxLayer offset={2.80} speed={0.2} style={{ opacity: 1 }}>
            <div className="birdsgrenoble2 birdsplage P4" />
            <div className="birdsgrenoble2 birdsplage P5" />
            <div className="birdsgrenoble2 birdsplage P6" />

            <img alt={'back Building'} src={require('../../assets/images/P4trans1.png')} style={{ position: 'absolute', right: -30, bottom: 25, display: 'block', width: '50%', marginLeft: '0%' }} />
          </ParallaxLayer>


          <ParallaxLayer offset={2.78} speed={0.3} style={{ opacity: 1 }}>
            <img alt={'Front Building'} src={require('../../assets/images/P4trans2.png')} style={{ position: 'absolute', right: -25, bottom: 25, display: 'block', width: '48%', marginLeft: '0%' }} />
          </ParallaxLayer>


          <ParallaxLayer offset={2.70} speed={0.45} style={{ opacity: 1 }}>
            <img alt={'three'} src={require('../../assets/images/P4trans3.png')} style={{ position: 'absolute', right: -15, bottom: 15, display: 'block', width: '25%', marginLeft: '0%' }} />
          </ParallaxLayer>


          {/* PARIS clicked elements */}
          <ParallaxLayer offset={0} speed={0.45} style={{ opacity: 1, bottom: "-32px" }}>

            {/*  clicked element amp */}
            <div className="amp"
              onClick={() => { this.props.handleOpenModal(2, 'images-paris') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

            {/*  clicked element Message left*/}
            <div className="message"
              onClick={() => { this.props.handleOpenModal(1, 'video-eric') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />

            {/*  clicked element Message right (fliped)*/}
            <div className="message msg2"
              onClick={() => { this.props.handleOpenModal(1, 'video-ciro-matthieu') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )   rotateY(180deg)` 	}} />


            {/*  clicked element cafe */}
            <div className="cafe"
              onClick={() => { this.props.handleOpenModal(3, 'articles-paris') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

            {/*  clicked element Message inside women left*/}
            <div className="message msgwomen1"
              onClick={() => { this.props.handleOpenModal(1, 'video-kaouther-olivier') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />

            {/*  clicked element Message inside women right (fliped)*/}
            <div className="message msgwomen2"
              onClick={() => { this.props.handleOpenModal(1, 'video-alexandre') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  rotateY(180deg)` 	}} />


            {/*  clicked element Amp inside women */}
            <div className="amp ampwomen"
              onClick={() => { this.props.handleOpenModal(1, 'video-bureaux-paris') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />

          </ParallaxLayer>

          {/* grenoble clicked elements */}
          <ParallaxLayer offset={1} speed={0.2} style={{ opacity: 1 }}>

            {/*  clicked element amp */}
            <div className="amp grenobleamp"
              onClick={() => { this.props.handleOpenModal(2, 'images-grenoble') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

            <div className="amp grenobleamp gamp2"
              onClick={() => { this.props.handleOpenModal(1, 'video-ski') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

            {/*  clicked element Message left*/}
            <div className="message grenoblemsg msg1"
              onClick={() => { this.props.handleOpenModal(1, 'video-theo-charles') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )  ` 	}} />

            {/*  clicked element Message right (fliped)*/}
            <div className="message grenoblemsg msg2"
              onClick={() => { this.props.handleOpenModal(1, 'video-mario-ghizlane') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )   rotateY(180deg)` 	}} />


            {/*  clicked element cafe */}
            <div className="cafe grenoblecafe"
              onClick={() => { this.props.handleOpenModal(3, 'articles-grenoble') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

            <div className="amp  transitionamp"
              onClick={() => { this.props.handleOpenModal(1, 'video-vendanges') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />

          </ParallaxLayer>

          {/* grenoble Castle clicked elements */}
          <ParallaxLayer offset={1.98} speed={0.23} style={{ opacity: 1 }}>

            {/*  clicked element amp */}
            <div className="amp  gcamp"
              onClick={() => { this.props.handleOpenModal(1, 'video-kart') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />


            {/*  clicked element Message */}
            <div className="message gcmsg"
              onClick={() => { this.props.handleOpenModal(1, 'video-olivier') }
              }
              style={{
                transform: `scale(	
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
												 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
												  )` 	}} />


          </ParallaxLayer>

          {/* Bordeaux Beach clicked elements */}
          <ParallaxLayer offset={2.33} speed={0.1} style={{ opacity: 1 }}>

            {/*  clicked element cafe */}
            <div className="cafe gccafe"
              onClick={() => { this.props.handleOpenModal(3, 'articles-bordeaux') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />


            {/*  clicked element amp */}
            <div className="amp  brdcamp"
              onClick={() => { this.props.handleOpenModal(1, 'video-NX-summer') }}
              style={{
                transform: `scale(	
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleX}) ) ,
						 calc( ${this.props.scaleX / 1.5} * (0.15  / ${this.props.scaleY}) )  
						  )  `
              }} />
          </ParallaxLayer>


        </Parallax>

      </span >

    );
  }
}
